import { useState } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import { logout } from '../redux/loginSlice';
import { selectLoginState, selectUserType, selectUserId } from '../redux/loginSlice';
import logo from '../images/logo-with-title.svg';
import './NavMenu.css';

export const NavMenu = () => {
    const [collapsed, setCollapsed] = useState(true);
    const isLoggedIn = useAppSelector(selectLoginState);
    const userId = useAppSelector(selectUserId);
    const userType = useAppSelector(selectUserType);
    const dispatch = useAppDispatch();

    const toggleNavbar = () => {
        setCollapsed(!collapsed)
    }

    const doLogout = () => {
        dispatch(logout());
    }

    return (
        <>
        <header>
            <Container fluid>
                <Row><Col xs="12">
            <Navbar className="navbar-expand-sm navbar-toggleable-sm default-top-menu" container='fluid' light>
                <NavbarBrand tag={Link} to="/"><img src={logo} /> {userType === "Admin" ? ' [Admin]' : ''}</NavbarBrand>
                <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                    <ul className="navbar-nav flex-grow">
                        <UncontrolledDropdown nav inNavbar className='dropdownMenu'>
                            <DropdownToggle nav caret>
                                Products
                            </DropdownToggle>
                            <DropdownMenu end>
                                <DropdownItem><NavLink tag={Link} className="text-dark" to="/socialaudit">Social Media Audit</NavLink></DropdownItem>
                                <DropdownItem><NavLink tag={Link} className="text-dark" to="https://kyndr.com" target="_blank">Kyndr</NavLink></DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        {!isLoggedIn &&
                            <UncontrolledDropdown nav inNavbar className='dropdownMenu'>
                                <DropdownToggle nav caret>
                                    Sign Up
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/register">Sign Up</NavLink></DropdownItem>
                                    <DropdownItem><NavLink tag={Link} className="text-dark" to="/login">Sign In</NavLink></DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>}
                        {isLoggedIn &&
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>
                                    My Account
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <DropdownItem ><NavLink tag={Link} className="text-dark" to="/manageAccount">Manage Account</NavLink></DropdownItem>
                                    <DropdownItem ><NavLink tag={Link} className="text-dark" onClick={doLogout}>Log Out</NavLink></DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>}
                    </ul>
                </Collapse>
            </Navbar>
            </Col></Row></Container>
            </header>
        </>
    );

}

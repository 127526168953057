import { Row, Col } from 'reactstrap';

const TextWithImageCard = ({children, image, className, layout, alignVertical, imageFirstWhenNarrow} : 
    {
        image: string,
        className?: string,
        layout?: 'full' | 'hang-left' | 'img-5' | 'reverse', 
        alignVertical?: 'bottom' | 'center',
        children: string | JSX.Element | JSX.Element[] | (() => JSX.Element)
        imageFirstWhenNarrow?: boolean
    } ) => {

        return (
        <Row className={className}>
            <Col 
                lg={{
                    offset: layout === 'full' || layout === 'reverse' ? 0 : 1,
                    size: layout === 'full' || layout === 'reverse' ? 6 
                        : layout === 'hang-left' ? 4
                        : 5,
                    order: layout === 'reverse' ? 2 : 1
                }}
                md={!imageFirstWhenNarrow ? "12" : {order: 2, size: 12}}
                sm={!imageFirstWhenNarrow ? "12" : {order: 2, size: 12}}
                xs={!imageFirstWhenNarrow ? "12" : {order: 2, size: 12}}
                style={{
                    display: 'flex', 
                    justifyContent: alignVertical === 'center' ? 'center' : 'flex-end', 
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <>{children}</>
            </Col>
            <Col 
                lg={{
                    offset: layout === 'hang-left' || layout === 'reverse' ? 1 : 0,
                    size: layout === 'img-5' || layout === 'reverse' ? 5 : 6,
                    order: layout === 'reverse' ? 1 : 2
                }}
                md={!imageFirstWhenNarrow ? "12" : {order: 1, size: 12}}
                sm={!imageFirstWhenNarrow ? "12" : {order: 1, size: 12}}
                xs={!imageFirstWhenNarrow ? "12" : {order: 1, size: 12}}
            >
                <div className="main-image">
                    <img src={image} style={{width: '100%'}} />
                </div>
            </Col>
        </Row>);
    }

export default TextWithImageCard;

import {useState} from 'react';
import { selectMemberLoginState, selectAdminLoginState } from '../redux/loginSlice';
import { useAppSelector } from '../redux/hooks';
import { AdminRoute, ProtectedRoute } from '../components/Navigation/protectedRoute';
import { Container, Row, Col, Card, CardImg, CardTitle, CardText, Button } from 'reactstrap';
import DashboardView from './dashboard';
import AdminDashboardView from './adminDashboardView';
import people from '../images/landing-banner.svg';
import bannerDetail from '../images/main-banner-detail1.svg';
import bannerDetail2 from '../images/main-banner-detail2.svg';
import auditFlameDiagram from '../images/audit-flame-diagram.svg';
import socialAuditTitle1 from '../images/social-audit-title1.svg';
import trendDecoding from '../images/trend-decoding-img.svg';
import contentAnalysisIcon from '../images/content-analysis-icon.svg';
import smileyBig from '../images/smiley-big.svg';
import searchComment from '../images/search-comment.svg';
import browserHash from '../images/browser-hash.svg';
import rewindClock from '../images/rewind-clock.svg';
import barGraph from '../images/bar-graph.svg';
import kyndrAdvert from '../images/kyndr-advert.svg';
import appStore from '../images/app-store.svg';
import googlePlay from '../images/google-play.svg';
import footerAdKyndr from '../images/footer-ad-kyndr.svg';
import footerAdAudit from '../images/footer-ad-audit.svg';
import googlePlayBlack from '../images/google-play-black.svg';
import appStoreBlack from '../images/app-store-black.svg';
import landingPageEdge1 from '../images/landing-page-edge-1.svg';
import landingPageEdge2 from '../images/landing-page-edge2.svg';
import './home.css';
import { Link } from "react-router-dom";
import { useIsNarrowView } from '../hooks/useIsNarrowView';
import { useGetDeviceType, DeviceType } from '../hooks/useGetDeviceType';
import RegisterModal from './registerModal';
import TextWithImageCard from '../components/Cards/TextWithImageCard';
import { DefaultCard, SideBySide } from '../components/Cards/SideBySide';

const ColorPanel = ({color, title, children, image, imagePlacement} : 
    {
        color: string, 
        title: string | JSX.Element, 
        image?: string,
        imagePlacement?: 'top' | 'right', 
        children: string | JSX.Element | JSX.Element[] | (() => JSX.Element)
    }) => {
        const isNarrowView = useIsNarrowView();

        if (image && imagePlacement === 'top') {
            
            if (!isNarrowView) {
                return (
                <div style={{flexGrow: 1, backgroundColor: color, borderRadius: 10, padding: 24, display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
                    <div><img src={image} style={{width: '100%', marginBottom: 10}} /></div>
                    <div className="colorPanelText">
                        <h4>{title}</h4>
                        <>{children}</>
                    </div>
                </div>);
            }
        }

        return (
        <div style={{flexGrow: 1, backgroundColor: color, borderRadius: 10, padding: 24}}>
            <div style={{display: 'flex', flexDirection: 'row', gap: 15}}>
                <div className="colorPanelText">
                    <h4>{title}</h4>
                    <>{children}</>
                </div>
                {image &&
                    <div style={{alignSelf: 'center'}}><img src={image} style={{width: 48}}/></div>
                }
            </div>
        </div>
        );}

const PanelGridColumn = ({children} : {children : string | JSX.Element | JSX.Element[] | (() => JSX.Element)}) => {
    return (
        <Col>
            <div style={{display: "flex", flexDirection: 'column', justifyContent: "stretch", height: '100%', gap: 10}}>
                <>{children}</>
            </div>
        </Col>);
}

const Home = () => {
    const isMemberLoggedIn = useAppSelector(selectMemberLoginState);
    const isAdminLoggedIn = useAppSelector(selectAdminLoginState);
    const isNarrowView = useIsNarrowView();
    const {deviceType, isMobile} = useGetDeviceType();
    const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);

    // When logged in, you see the dashboard instead of the normal main page
    if (isMemberLoggedIn) {
        return (<ProtectedRoute><DashboardView /></ProtectedRoute>);
    } else if (isAdminLoggedIn) {
        return (<AdminRoute><AdminDashboardView /></AdminRoute>)
    }

    const getClassName = (className: string) : string => `${className}${isMobile ? " mobile" : ""}${isNarrowView ? " narrow" : ""}`

    const toggleRegisterModal = () => setIsRegisterModalOpen(!isRegisterModalOpen);

    return (
        <>
            <RegisterModal isOpen={isRegisterModalOpen} toggle={toggleRegisterModal} />
            <Container fluid>
                <TextWithImageCard image={people} className={getClassName("landing-main")}>
                    <div style={{paddingBottom: 35}}>
                        <h1><span className="title-color">Redefining Possibilities</span> 
                        &nbsp; In the Digital Realm</h1>
                        <>
                            <p>Welcome to Infiniscape, where innovation meets compassion. We harness technology for 
                                good with Kyndr, a community-driven social media app and our groundbreaking Social 
                                Media Audit. At Infiniscape, we're not just reimagining social media &mdash; we're 
                                revolutionizing it, one act of kindness at a time.</p> 
                            <p>Join Us in building a digital 
                                landscape where positivity thrives and meaningful connections flourish.</p>
                        </>
                    </div>
                </TextWithImageCard>
                <div className={isMobile ? "divider-gradient mobile" : "divider-gradient"}>
                    {isMobile ? 
                        <p> Empower Connections, Inspire Growth, and Redefine Digital Interactions with Kindness and Authenticity. </p>
                    : <>
                        <img src={bannerDetail} style={{position: 'absolute', bottom: 0, left: 0, zIndex: 1}}/>
                        <img src={bannerDetail2} style={{position: 'absolute', top: 0, right: 0, zIndex: 1}}/>
                        <div style={{zIndex: 5}}>
                            Empower Connections, Inspire Growth, and Redefine Digital Interactions with Kindness and Authenticity.
                        </div>
                    </>}
                    
                </div>
                <TextWithImageCard
                    image={auditFlameDiagram} 
                    className={isMobile ? "landing-sa mobile" : "landing-sa"}
                    layout="hang-left"
                    alignVertical="center"
                >
                    <div>
                        <img src={socialAuditTitle1} style={{width: '100%', marginBottom: 16}} /> 
                        <p>Traditional analytics only scratch the surface. Our audit dives deep, decoding trends and themes to reveal actionable insights to better understand your audience.</p>
                        <div className={`sign-up-learn-more${isMobile ? ' mobile' : ''}${isNarrowView ? ' narrow' : ''}`}>
                            <Button color="primary" onClick={toggleRegisterModal}>Sign up</Button>
                            <Link to="/socialaudit" className="primary-link">Learn more &gt;</Link>
                        </div>
                    </div>
                </TextWithImageCard>
                <Row
                    xl="4" 
                    lg="2"
                    md="2"
                    sm="1"
                    xs="1"
                    className="panel-grid"
                >
                    <PanelGridColumn>
                        <ColorPanel title="Trend Decoding" color="#E2D8FE">
                            <p>Identify and understand trends that resonate with your audience.</p>
                            <img src={trendDecoding} style={{width: '100%'}} />     
                        </ColorPanel>
                        <ColorPanel title={(<span>Comprehen<wbr />sive Reports</span>)} color="#D7F6E7" image={searchComment}>
                            <p>Receive detailed reports with actionable insights.</p>
                        </ColorPanel>
                    </PanelGridColumn>
                    <PanelGridColumn>
                        <ColorPanel title="Deep Content Analysis" color="#FFE7ED" image={contentAnalysisIcon}>
                            <p>Beyond traditional metrics, we dive deep to uncover what's truly working in your content.</p>   
                        </ColorPanel>
                        <ColorPanel title="Sentiment Tracking" color="#F2F6D7" image={smileyBig} imagePlacement='top'>
                            <p>Gauge audience sentiment towards your brand for tailored messaging.</p>
                        </ColorPanel>
                    </PanelGridColumn>
                    <PanelGridColumn>
                        <ColorPanel title="Engagement Insights" color="#D7F6F0" image={searchComment} imagePlacement='top'>
                            <p>Analyze beyond likes and shares to understand genuine audience interaction.</p>
                        </ColorPanel>
                    </PanelGridColumn>
                    <PanelGridColumn>
                        <ColorPanel title="Hashtag Optimization" color="#D7EFF6" image={browserHash}>
                            <p>Optimize your hashtag strategy for maximum reach - we'll provide SEO tips to match!</p>
                        </ColorPanel>
                        <ColorPanel title="Quick Turnaround" color="#F6D7D7" image={rewindClock}>
                            <p>Efficient audits ready for review in as little as one business day.</p>
                        </ColorPanel>
                        <ColorPanel title={(<span>Custom Suggest<wbr />ions</span>)} color="#D7D8F6" image={barGraph}>
                            <p>Get expert-led, AI assisted recommendations that are tailored to match your vibe.</p>
                        </ColorPanel>
                    </PanelGridColumn>
                </Row>
                <div className={isNarrowView ? "grayPanel narrow" : "grayPanel"}>
                    <p>Traditional analytics can only take you so far. Let us dive deeper into your 
                        content to see what's working &mdash; and what's not.</p>
                    <div className={`sign-up-learn-more${isMobile ? ' mobile' : ''}${isNarrowView ? ' narrow' : ''}`}>
                        <Button color="primary" onClick={toggleRegisterModal}>Sign up</Button>
                        <Link to="/socialaudit" className="primary-link">Learn more &gt;</Link>
                    </div>
                </div>
                
                <TextWithImageCard 
                    image={kyndrAdvert} 
                    className={getClassName("kyndr-advert")}  
                    alignVertical="center"
                    layout='img-5'
                    imageFirstWhenNarrow={true}
                >
                    <p className="title1">Social Media App</p>
                    <p className="title2">Meet people in a more meaningful way - connect authentically in a kinder space.</p>
                    <p className="body">Come join us on a journey through personal growth and help us create a safer, kinder space to connect with others.</p>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: isNarrowView ? "center" : "flex-start", width: '100%', gap: 10}}>
                        {(deviceType === DeviceType.Ios || deviceType === DeviceType.Desktop) &&
                            <a href="https://apps.apple.com/us/app/kyndr-online-community/id1610295080" target="_blank"><img src={appStore}/></a>}
                        {(deviceType === DeviceType.Android || deviceType === DeviceType.Desktop) &&
                            <a href="https://play.google.com/store/apps/details?id=com.infiniscape" target="_blank"><img src={googlePlay}/></a>}
                    </div>
                    <div className="learn-more">
                        <a href="https://kyndr.com" target="_blank">Learn more &gt;</a>
                    </div>
                </TextWithImageCard>
                <Row>
                    <Col className={getClassName("advert-header")}><h3>Join the Revolution!</h3></Col>
                </Row>

                <SideBySide >
                    <DefaultCard
                        className={getClassName('advert-card')}
                        title="Kyndr: The Social Media App for Communities"
                        subHeader='Experience a kinder, safer social media environment with Kyndr. Download now on the App Store or Google Play.'
                        image={footerAdKyndr}
                    >
                        <div className={getClassName("app-store-icons")}>
                        {(deviceType === DeviceType.Ios || deviceType === DeviceType.Desktop) &&
                            <a href="https://apps.apple.com/us/app/kyndr-online-community/id1610295080" target="_blank"><img src={appStoreBlack}/></a>}
                        {(deviceType === DeviceType.Android || deviceType === DeviceType.Desktop) &&
                            <a href="https://play.google.com/store/apps/details?id=com.infiniscape" target="_blank"><img src={googlePlayBlack}/></a>}
                    </div>
                    </DefaultCard>
                    <DefaultCard
                        className={getClassName('advert-card')}
                        title="Expert-Led AI Insights for Your Social Media Channels"
                        subHeader='Gain deep, actionable insights with our Social Media Audit. Sign up today to revolutionize your online presence.'
                        image={footerAdAudit}
                    >
                        <span style={{marginTop: 26, textAlign: 'center'}}><Button color="primary" style={{width: '90%'}} onClick={toggleRegisterModal}>Sign up</Button></span>
                    </DefaultCard>
                </SideBySide>
            </Container>
        </>
    );
}

export default Home;

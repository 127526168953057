import { useState } from 'react';
import { BusyButton } from '../Buttons/busyButton';
import { selectLoginState, selectTemporaryToken } from '../../redux/loginSlice';
import {  Form, FormGroup, Label, Input,UncontrolledAlert, Alert } from 'reactstrap';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { useForgotPasswordMutation, useGetRegistrationRequirementsQuery } from '../../redux/api/loginApi';
import "./login.css";

const ForgotPassword = () => {
    const isLoggedIn = useAppSelector(selectLoginState);
    const temporaryToken = useAppSelector(selectTemporaryToken);
    const [enteredCode, setEnteredCode] = useState<string>();
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const { data: registrationRequirements } = useGetRegistrationRequirementsQuery();
    const dispatch = useAppDispatch();
    const [
        sendForgotPassword, // This is the mutation trigger
        { isLoading, isError, isSuccess }, // This is the destructured mutation result
    ] = useForgotPasswordMutation()

    const forgotPassword = () => {
        sendForgotPassword({ email });
        //dispatch(loginAsync({ loginName: "phil3", password: "Abcd1234", rememberMe: false }));
    }

    return (
        <Form className="loginForm">
            <FormGroup>
                <Label for="email">
                    Email
                </Label>
                <Input
                    id="email"
                    name="email"
                    placeholder="enter your email address"
                    type="text"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
            </FormGroup>
            {isError && <FormGroup><UncontrolledAlert color="danger">There was a problem resetting your password. Please try again.</UncontrolledAlert></FormGroup>}
            {isSuccess && <FormGroup><Alert>Please check your email for instructions on resetting your password</Alert></FormGroup> }
            <BusyButton
                label="Reset"
                onClick={forgotPassword}
                isBusy={isLoading}
                className="loginSubmit"
                disabled={email.length === 0 }
            />
        </Form>
    );
}

export default ForgotPassword;

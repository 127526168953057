import {useState, useEffect} from 'react';

const getWidth = () : Width => {
    if (window.innerWidth >= 1400 ) return Width.ExtraExtraLarge;
    if (window.innerWidth >= 1200 ) return Width.ExtraLarge;
    if (window.innerWidth >= 992 ) return Width.Large;
    if (window.innerWidth >= 768 ) return Width.Medium;
    if (window.innerWidth >= 576 ) return Width.Small;
    return Width.ExtraSmall;
}

export enum Width {
    ExtraSmall= 0,
    Small = 1,
    Medium = 2,
    Large = 3,
    ExtraLarge = 4,
    ExtraExtraLarge = 5
}

const computeIsNarrowView = () : boolean => window.innerWidth < 768;

export const useIsNarrowView = () =>  {
    const [isNarrowView, setIsNarrowView] = useState(computeIsNarrowView());

    useEffect(() => {
        const onResize = () => {
            setIsNarrowView(computeIsNarrowView());
        }

        window.addEventListener("resize", onResize);
    
        return () => {
            window.removeEventListener("resize", onResize);
        }
    }, []);
    
    return isNarrowView;
}

export const useWindowWidth = () =>  {
    const [isNarrowView, setIsNarrowView] = useState(computeIsNarrowView());
    const [width, setWidth] = useState(getWidth());

    useEffect(() => {
        const onResize = () => {
            setIsNarrowView(computeIsNarrowView());
            setWidth(getWidth());
        }

        window.addEventListener("resize", onResize);
    
        return () => {
            window.removeEventListener("resize", onResize);
        }
    }, []);
    
    return width;
}

import { Link } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import Register from '../components/Login/register';

const RegisterView = () => {

    return (
        <Container>
            <Row>
                <Register onRegister={() => console.log("register") } />
            </Row>
            <Row className="loginLinkFooter">
                <span>Already have an account? <Link to="/login" className="primary-link login-link">Log in</Link></span>
            </Row>
            <Row className="loginLinkFooter">
                <span>By clicking "Create account", I agree to Infiniscape's 
                {' '}<Link to="/termsandconditions" className="primary-link login-link">TOS</Link> and 
                {' '}<Link to="/privacy" className="primary-link login-link">Privacy Policy</Link>.</span>
            </Row>
        </Container>
    );
}

export default RegisterView;

import { useState, useEffect } from 'react'
import { Link, Navigate } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import { selectLoginState, selectUserType } from '../../redux/loginSlice';
import { useAppSelector } from '../../redux/hooks';
import auditFlameDiagram from '../../images/audit-flame-diagram-2.svg';
import curvesLevelGraph from '../../images/curves-level-graph.svg';
import soundRecognitionSearch from '../../images/sound-recognition-search.svg';
import arrowCursorClick from '../../images/arrow-cursor-click.svg';
import shieldcheck from '../../images/shield-check.svg';
import nodes from '../../images/nodes.svg';
import userSticker from '../../images/user-sticker.svg';
import TextWithImageCard from '../../components/Cards/TextWithImageCard';
import socialAuditHeader from '../../images/social-audit-header.svg';
import scanText from '../../images/scan-text.svg';
import userFeedbackHeart from '../../images/user-feedback-heart.svg';
import graphBarIncrease from '../../images/graph-bar-increase.svg';
import socialAuditFooterDetail from '../../images/social-audit-footer-detail.svg';
import { useWindowWidth, Width } from '../../hooks/useIsNarrowView';
import { useGetDeviceType } from '../../hooks/useGetDeviceType';
import RegisterModal from '../registerModal';
import "./socialAuditDescriptionView.css";
import { SideBySide, DefaultCard } from '../../components/Cards/SideBySide';

const LoggedInEnrollModal = ({isOpen} : 
    {
        isOpen: boolean,
        toggle: () => void
    }) => {
        if (!isOpen) return (null);
        return (<Navigate to="/" />);
}

const ColorPanel = ({title, children, image, imagePlacement, className} : 
    {
        title: string | JSX.Element, 
        image?: string,
        imagePlacement?: 'top' | 'left', 
        className?: string,
        children: string | JSX.Element | JSX.Element[] | (() => JSX.Element)
    }) => {

        return (
        <div style={{flexGrow: 1, borderRadius: 10}} className={`social-audit-color-panel${className ? " " + className : ""}`}>
            {imagePlacement === 'top' && image &&
                <div style={{alignSelf: 'start', marginBottom: 16, marginTop: 16}}><img src={image} style={{width: 48}}/></div>} 

            <div style={{display: 'flex', flexDirection: 'row', gap: 15}}>
                {imagePlacement !== 'top' && image &&
                    <div style={{alignSelf: 'start'}}><img src={image} style={{width: 48}}/></div>
                }
                <div style={{width: '100%'}}>
                    <h4>{title}</h4>
                    <>{children}</>
                </div>
                
            </div>
        </div>
        );}

const SocialAuditDescriptionView = () => {
    const isLoggedIn = useAppSelector(selectLoginState);
    const userType = useAppSelector(selectUserType);
    const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
    const windowWidth = useWindowWidth();
    const {deviceType, isMobile} = useGetDeviceType();

    const toggleRegisterModal = () => setIsRegisterModalOpen(!isRegisterModalOpen);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const summaryPanelImagePlacement = windowWidth > Width.Medium ? 'top' : 'left';
    const isNarrowView = windowWidth < Width.Medium;

    const SignUpOrEnroll = () => (
        <div className={`social-audit-signup${isMobile ? ' mobile' : ''}${isNarrowView ? ' narrow' : ''}`}>
            <Button color="primary" onClick={toggleRegisterModal}>{isLoggedIn ? 'Enroll' : 'Sign Up'}</Button>
        </div>);

    return (
        <>
            {!isLoggedIn 
                ? <RegisterModal isOpen={isRegisterModalOpen} toggle={toggleRegisterModal} />
                : <LoggedInEnrollModal isOpen={isRegisterModalOpen} toggle={toggleRegisterModal} />}
            
            <Container fluid className={`social-audit-container ${isNarrowView ? " narrow": ""}`}>
                <Row>
                    <Col 
                        lg={{
                            offset: 2,
                            size: 8,
                        }}
                        md={{size: 12}}
                        sm={{size: 12}}
                        xs={{size: 12}}
                    >
                        <div>
                            <img src={socialAuditHeader} style={{width: '100%'}} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={{offset:3, size: 6}} className={`social-audit-header-text ${isNarrowView ? " narrow": ""}`}>
                        <span >Your Online Success Story Starts Here</span>
                    </Col>
                </Row>
                <TextWithImageCard
                    image={auditFlameDiagram} 
                    layout="reverse"
                    alignVertical="center"
                    className='social-top-2'
                    imageFirstWhenNarrow={true}
                >
                    <div className="main-content" style={{display: 'flex', flexDirection: 'column', gap: 15}}>
                        <div>
                            <h1>Don't settle for mediocre metrics</h1>
                            <p>Traditional analytics only take you so far. We dive deeper into your content to see what's working and what's not. 
                                We decode trends, dissect themes, and provide pathways to better understand your audience. From sentiment tracking 
                                to engagement analysis, we uncover actionable insights.</p>
                        </div>
                        <ColorPanel title="Identification of Top-Performing Themes" image={scanText}>
                            Uncover what resonates with your audience and capitalize on it.
                        </ColorPanel>
                        <ColorPanel title="Sentiment Classification" image={userFeedbackHeart}>
                            Understand your audience's feelings about your brand and tailor your message accordingly.
                        </ColorPanel>
                        <ColorPanel title="Engagement Analysis" image={graphBarIncrease}>
                            Go beyond likes and shares to truly understand audience interaction with your content.
                        </ColorPanel>
                        <SignUpOrEnroll />
                    </div>
                </TextWithImageCard>

                <Row className={`social-audit-summary-panel ${summaryPanelImagePlacement}`}>
                    <Col>
                        <div className="title"><h3>Tired of drowning in social media metrics that don't tell you much? </h3></div>
                        <div className="sub-title">We can help you build a true community from your followers</div>
                        <div className="layout-grid">
                            <ColorPanel title="Strategic Investment" image={curvesLevelGraph} imagePlacement={summaryPanelImagePlacement} className={`${summaryPanelImagePlacement}`}>
                                Creating content is an investment in your brand's future, not just posting for the sake of it.
                            </ColorPanel>
                            <ColorPanel title="Efficient Audits" image={soundRecognitionSearch} imagePlacement={summaryPanelImagePlacement} className={`${summaryPanelImagePlacement}`}>
                                Experience unmatched efficiency with audits ready for review in as little as one business day.
                            </ColorPanel>
                            <ColorPanel title="Hassle-Free Process" image={arrowCursorClick} imagePlacement={summaryPanelImagePlacement} className={`${summaryPanelImagePlacement}`}>
                                Forget sending screenshots or searching for top-performing content—use our secure login to connect your accounts, and we'll handle the rest.
                            </ColorPanel>
                            <ColorPanel title="Unmatched Precision" image={shieldcheck} imagePlacement={summaryPanelImagePlacement} className={summaryPanelImagePlacement}>
                                Created by data-scientists, our audit ensures precision, insight and results driven by a deep understanding of user behavior.
                            </ColorPanel>
                            <ColorPanel title="Multiple Channels" image={nodes} imagePlacement={summaryPanelImagePlacement} className={summaryPanelImagePlacement}>
                                We'll audit your profiles on the most popular social platforms.
                            </ColorPanel>
                            <ColorPanel title="Satisfaction Guaranteed" image={userSticker} imagePlacement={summaryPanelImagePlacement} className={summaryPanelImagePlacement}>
                                Our satisfaction guarantee ensures that if anything feels amiss, we're just an email away.
                            </ColorPanel>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col className={`social-audit-advert-container ${summaryPanelImagePlacement}`}>
                        <div className="title"><h3>Prices Tailored to Your Needs</h3></div>
                        <div className="sub-title">
                            <span>Whether you're looking to grow your personal brand or in the market for a new business development tool &mdash; we've got a package for you!</span>
                        </div>
                        <SideBySide className="social-audit-advert">
                            <DefaultCard className="standard">
                                <div style={{display: 'flex', flexDirection: 'column', gap: 24}}>
                                    <div><h4>Standard</h4></div>
                                    <div><span className="pricing">$99</span>/ audit</div>
                                    <p>Get started today!</p>
                                    <SignUpOrEnroll />
                                </div>
                            </DefaultCard>
                            <DefaultCard className='high-volume'>
                                <div style={{display: 'flex', flexDirection: 'column', gap: 24}}>
                                    <div><h4>High Volume Audit</h4></div>
                                    <div className='lets-talk'>Let's talk!</div>
                                    <p>High-volume audits that need extensive resources. We offer custom pricing!</p>
                                    <Link to="mailto:support@infiniscape-inc.com"><Button color="secondary" outline>Contact Us</Button></Link>
                                </div>
                            </DefaultCard>
                        </SideBySide>
                    </Col>
                </Row>

                <Row>
                    <Col>
                    <div className={`social-audit-footer${isNarrowView ? " narrow": ""}`}>
                        {isMobile ? 
                                <div><p>Don't settle for mediocre metrics. Invest in your online success with our Social Media Audit today!</p>
                            <SignUpOrEnroll />
                            </div>
                        : <>
                            <img src={socialAuditFooterDetail} style={{position: 'absolute', right: 125, zIndex: 1}}/>
                            <div style={{position: 'relative', zIndex: 5}}>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <div style={{maxWidth: 810}}>Don't settle for mediocre metrics. Invest in your online success with our Social Media Audit today!</div>
                                </div>                         
                                <SignUpOrEnroll />
                            </div>
                            
                        </>}
                    </div>
                    </Col>
                </Row>
                
            </Container>
        </>
    );
}

export default SocialAuditDescriptionView;

import {useState, useEffect} from 'react';

export enum DeviceType {
    Desktop,
    Android,
    Ios
}

interface DeviceTypeResult {
    deviceType : DeviceType | undefined,
    isMobile : boolean;
}

const getUserAgent = () : string | undefined => 
    window.navigator?.userAgent;

export const useGetDeviceType = () : DeviceTypeResult =>  {
    const [deviceType, setDeviceType] = useState<DeviceType | undefined>(undefined);

    useEffect(() => {
        const userAgent = getUserAgent();
        if (!userAgent) {
            setDeviceType(undefined);
        } else if (!userAgent.includes("Mobi")) {
            setDeviceType(DeviceType.Desktop);
        } else if (/android/i.test(userAgent)) {
            setDeviceType(DeviceType.Android);
        } else if (/iPad|iPhone|iPod/.test(userAgent)){
            setDeviceType(DeviceType.Ios);
        } else {
            setDeviceType(undefined);
        }
    }, []);
    
    return {deviceType, isMobile: deviceType === DeviceType.Android || deviceType === DeviceType.Ios};
}

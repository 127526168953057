import React, { useEffect, useState } from 'react';
import { BusyButton } from '../Buttons/busyButton';
import { selectLoginState, selectTemporaryToken } from '../../redux/loginSlice';
import { Container, Row, Form, FormGroup, Label, Input, FormFeedback, Button, Spinner, FormText, UncontrolledAlert } from 'reactstrap';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { useNavigate } from "react-router-dom";
import { RegistrationRequirement, useConfirmEmailMutation, useForgotPasswordMutation, useGetRegistrationRequirementsQuery, useLoginMutation, useResendEmailConfirmationMutation, useResetPasswordMutation } from '../../redux/api/loginApi';
import "./login.css";

type ResetPasswordArgs = {
    token: string
}

type ValidationCheck = {
    message: string,
    error: boolean
}

const ResetPassword = ({token } : ResetPasswordArgs) => {
    const isLoggedIn = useAppSelector(selectLoginState);
    const temporaryToken = useAppSelector(selectTemporaryToken);
    const [enteredCode, setEnteredCode] = useState<string>();
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [passwordErrors, setPasswordErrors] = useState<ValidationCheck[]>([]);
    const { data: registrationRequirements } = useGetRegistrationRequirementsQuery();
    const dispatch = useAppDispatch();
    const [
        sendResetPassword, // This is the mutation trigger
        { isLoading, isError }, // This is the destructured mutation result
    ] = useResetPasswordMutation();
    const navigate = useNavigate();

    const resetPassword = async () => {
        await sendResetPassword({ newPassword: password, token }).unwrap();
        navigate('/login');
    }

    const setPasswordValue = (value: string) => {
        setPasswordErrors(validateInput(value, registrationRequirements?.passwordRequirements));
        setPassword(value);
    }

    const validateInput = (value: string, requirements: RegistrationRequirement[] | undefined) => {
        let errors: ValidationCheck[] = [];
        if (!value || value.length == 0 || !requirements)
            return errors;
        for (let i = 0; i < requirements.length; i++) {
            let requirement = requirements[i];
            switch (requirement.type) {
                case "length":
                    errors.push({
                        message: requirement.errorMessage,
                        error: requirement.minLength != null && value.length < requirement.minLength
                    });
                    break;
                case "regex":
                    errors.push({
                        message: requirement.errorMessage,
                        error: requirement.expression != null && !new RegExp(requirement.expression).test(value)
                    });
                    break;
                default:
                    break;
            }
        }
        return errors.filter(err => err.error);
    }

    // TODO: Need to have a confirm your password box

    return (
        <Form className="loginForm">
            <FormGroup>
                <Label for="registerPassword">
                    New Password
                </Label>
                <Input
                    id="registerPassword"
                    name="password"
                    placeholder="choose a password"
                    type="password"
                    value={password}
                    onChange={e => setPasswordValue(e.target.value)}
                    valid={password != null && password.length > 0 && passwordErrors.length == 0}
                    invalid={passwordErrors.length > 0}
                />
                {passwordErrors.length > 0 &&
                    <FormFeedback >
                        The password must:
                        {passwordErrors.map((error, i) => <div key={i}>{error.message}</div>)}
                    </FormFeedback>
                }
            </FormGroup>
            {isError && <FormGroup><UncontrolledAlert color="danger">There was a problem resetting your password. Please try again.</UncontrolledAlert></FormGroup>}
            <BusyButton
                disabled={password.length === 0 || passwordErrors.length > 0}
                isBusy={isLoading}
                label="Submit"
                className="loginSubmit"
                onClick={resetPassword}
            />
        </Form>
    );
}

export default ResetPassword;

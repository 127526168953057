import { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Container, Form, FormGroup, Button, Row } from 'reactstrap';
import Login from '../components/Login/login';

const LoginView = () => {

    return (
        <Container>
            <Row>
                <Login />
            </Row>
            <Row className="loginLinkFooter">
                <span>I've forgotten my password.
                {' '}<Link to="/forgotPassword" className="primary-link login-link">Reset it.</Link></span>
            </Row>
            <Row  className="loginLinkFooter">
                <span>I don't have an account.
                {' '}<Link to="/register" className="primary-link login-link">Create your account</Link></span>
            </Row>
        </Container>
    );
}

export default LoginView;

import {useState} from 'react';
import { Row, Col, Card, CardImg, CardTitle, CardText } from 'reactstrap';
import landingPageEdge1 from '../../images/landing-page-edge-1.svg';
import landingPageEdge2 from '../../images/landing-page-edge2.svg';
import { useWindowWidth, Width } from '../../hooks/useIsNarrowView';
import { useGetDeviceType } from '../../hooks/useGetDeviceType';

export const SideBySide = ({children,className} : 
    {
        className?: string,
        children: JSX.Element[]
    }) => {
    const windowWidth = useWindowWidth();
    const {isMobile} = useGetDeviceType();

    const isNarrowView = windowWidth < Width.Medium;

    return (<div style={{width: '100%', position: 'relative'}}>
        {!isNarrowView && 
            <img src={landingPageEdge1} style={{position: 'absolute', top: -150, left: 0, zIndex: -1}}/>}
        {!isNarrowView && 
            <img src={landingPageEdge2} style={{position: 'absolute', bottom: -260, right: 0, zIndex: -1}}/>}
        <Row className={`default-card-row${className ? " " + className : ""}${isNarrowView ? " narrow": ""}`} >
            <Col 
                lg={{
                    offset: 2,
                    size: 4
                }}
                md="6"
                sm="12"
                xs="12"
            >
                {children[0]}
            </Col>
            <Col
                lg={{
                    offset: 0,
                    size: 4
                }}
                md="6"
                sm="12"
                xs="12"
            >
                {children[1]}
            </Col>
        </Row>
    </div>);
}

export const DefaultCard = ({children, image, className, title, subHeader} : 
    {
        image?: string,
        className?: string,
        title?: string,
        subHeader?: string,
        children: string | JSX.Element | JSX.Element[] | (() => JSX.Element)
    }) => {
    
    return (
    <Card body className={`default-card${className ? " " + className : ""}`}>
        {image && <CardImg
            alt="Card image cap"
            src={image}
            top
            height={180}
        />}
        {title && 
            <CardTitle>{title}</CardTitle>}
        {subHeader && 
            <CardText>{subHeader}</CardText>}
        <>{children}</>
    </Card>);
}


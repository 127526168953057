import {FC} from 'react';
import RegisterView from './registerView';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import './registerModal.css';

type RegisterModalProps = {
    toggle: () => void,
    isOpen: boolean,
}

const RegisterModal : FC<RegisterModalProps> = ({toggle, isOpen}) => {


    return (
        <Modal isOpen={isOpen} toggle={toggle} className={"registerModal"}>
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody>
                <RegisterView />
            </ModalBody>
        </Modal>
    );
}

export default RegisterModal;

import { JSX, FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { selectLoginState, selectIsEmailUnverified, selectAdminLoginState, selectIsMfaRequired, selectUserType } from '../../redux/loginSlice';

type Props = {
    children: string | JSX.Element | JSX.Element[] | (() => JSX.Element)
}

type ProtectedRouteProps = {
    // Optional content to display if not logged in. Overrides redirect to login page
    notLoggedInContent?: JSX.Element | (() => JSX.Element),
    children: string | JSX.Element | JSX.Element[] | (() => JSX.Element)
}

// Wraps routes that require authentication
export const ProtectedRoute : FC<ProtectedRouteProps> = ({ notLoggedInContent, children } : ProtectedRouteProps)  => {
    const isLoggedIn = useAppSelector(selectLoginState);
    const userType = useAppSelector(selectUserType);
    const isEmailUnverified = useAppSelector(selectIsEmailUnverified);
    const isMfaRequired = useAppSelector(selectIsMfaRequired);
    const location = useLocation();

    const returnUrl = location.state ? (location.state["loginReturn"] ?? location.pathname) : location.pathname;

    if (!isLoggedIn)
        return notLoggedInContent 
            ? (<>{notLoggedInContent}</>) 
            : (<Navigate to="/login" state={{ loginReturn: returnUrl }} replace />);
    if (userType !== "Member")
        return (<Navigate to="/" state={{ loginReturn: returnUrl }} replace />);
    if (isEmailUnverified)
        return (<Navigate to="/verifyEmail" state={{ loginReturn: returnUrl }} replace />);
    if (isMfaRequired)
        return (<Navigate to="/initiateMfa" state={{ loginReturn: returnUrl }} replace />);
    return (<>{children}</>)
}

export const AdminRoute = ({ children }: Props) => {
    const isLoggedIn = useAppSelector(selectAdminLoginState);
    const isEmailUnverified = useAppSelector(selectIsEmailUnverified);
    const isMfaRequired = useAppSelector(selectIsMfaRequired);
    const location = useLocation();

    const returnUrl = location.state ? (location.state["loginReturn"] ?? location.pathname) : location.pathname;

    if (!isLoggedIn)
        return (<Navigate to="/login" state={{ loginReturn: returnUrl }} replace />);
    if (isEmailUnverified)
        return (<Navigate to="/verifyEmail" state={{ loginReturn: returnUrl }} replace />);
    if (isMfaRequired)
        return (<Navigate to="/initiateMfa" state={{ loginReturn: returnUrl }} replace />);
    return (<>{children}</>)
}

// Wraps routes that are only available if unauthenticated
export const UnprotectedRoute = ({ children }: Props) => {
    const isLoggedIn = useAppSelector(selectLoginState);
    const location = useLocation();

    if (!isLoggedIn)
        return (<>{children}</>); 

    const returnUrl = location.state ? location.state["loginReturn"] : null;
    if (returnUrl)
        return (<Navigate to={returnUrl} replace/>);
    return (<Navigate to="/" replace />);
}

// Wraps routes that are only available if logged in and the email is not verified
export const UnverifiedRoute = ({ children }: Props) => {
    const isLoggedIn = useAppSelector(selectLoginState);
    const isEmailUnverified = useAppSelector(selectIsEmailUnverified);
    const location = useLocation();

    const returnUrl = location.state ? location.state["loginReturn"] : null;

    if (!isLoggedIn)
        return (<Navigate to="/login" state={{ loginReturn: returnUrl }} replace />);
    if (isEmailUnverified)
        return (<>{children}</>)
    if (returnUrl)
        return (<Navigate to={returnUrl} replace />);
    return (<Navigate to="/" replace />);
}

export const MfaRoute = ({ children }: Props) => {
    const isLoggedIn = useAppSelector(selectLoginState);
    const userType = useAppSelector(selectUserType);
    const isEmailUnverified = useAppSelector(selectIsEmailUnverified);
    const isMfaRequired = useAppSelector(selectIsMfaRequired);
    const location = useLocation();

    const returnUrl = location.state ? location.state["loginReturn"] : null;

    if (!isLoggedIn)
        return (<Navigate to="/login" state={{ loginReturn: returnUrl }} replace />);
    if (userType !== "Admin")
        return (<Navigate to="/" state={{ loginReturn: returnUrl }} replace />);
    if (isEmailUnverified)
        return (<Navigate to="/verifyEmail" state={{ loginReturn: returnUrl }} replace />);
    if (isMfaRequired)
        return (<>{children}</>)
    if (returnUrl)
        return (<Navigate to={returnUrl} replace />);
    return (<Navigate to="/" replace />);
}
